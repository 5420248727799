import React from 'react'
import {
  Wrapper,
  Video,
} from '../../../components'

const VideoServe = () => (

  <Wrapper
    flexDirectionD="column"
    displayM="box"
    paddingD="80px 0">
    <Wrapper
      widthD="1920px"
      flexDirectionD="column"
    >
      {/* <Video/> */}
    </Wrapper>
  </Wrapper>
)

export default VideoServe
